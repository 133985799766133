*,
:before,
:after {
  box-sizing: unset;
}

.selects {
  display: flex;
  margin: 10px;
}

.selects>select {
  padding: 6px 12px;
  background-color: #337ab7;
  border: 1px #2e6da4 solid;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.428;
  color: white;
}

.selects>select>option {
  background-color: unset;
  border: 0px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.428;
  color: white;
}

.team-icon-small {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.ant-slider-dot,
.ant-slider-handle,
.ant-tooltip * {
  box-sizing: border-box;
}

.btn-filter button {
  padding: 0 3px;
  margin-bottom: 2px;
}

.btn-filter.select-all button {
  background-color: forestgreen;
  border-color: forestgreen;
}

.btn-filter.deselect-all button {
  background-color: crimson;
  border-color: crimson;
}

/*
.ui, .ui input, .ui *, .ui *:before, .ui *:after {
  box-sizing: inherit;
} */

.ui.radio.checkbox label:after {
  top: 2px;
  left: 1px;
  transform: scale(0.7);
}

.ui.button {
  margin: 0 .25em 0 0
}

.field {
  box-sizing: inherit;
}

.error-container {
  margin-top: 20px;
  text-align: center;
}

.info-header {
  font-weight: bold;
  margin: 5px;
}

.game-stats {
  margin-bottom: 20px;
}

.ReactTable .rt-noData {
  position: relative;
  left: unset;
  top: unset;
  transform: unset;
  background: rgba(0, 0, 0, 0.03);
  padding: 7px;
}

.ReactTable .rt-thead .rt-th {
  font-weight: bold;
  padding: 0px;
}

.ReactTable .rt-thead .rt-th div div,
.ReactTable .rt-thead .rt-th .non-popup {
  font-weight: bold;
  padding: 7px 5px;
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

button,
input {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ui.button.primary:not(:disabled):not(.disabled).active,
.ui.button.primary:not(:disabled):not(.disabled):active,
.show>.ui.button.primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn,
.ui.button {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  min-width: 16px;
}

.ui.button.primary,
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
}

.btn.disabled,
.btn:disabled {
  opacity: .65;
}

.ant-slider {
  max-width: 200px
}

.ant-slider-dot-active {
  border-color: #e8e8e8;
}

.ant-slider-track {
  background-color: #e8e8e8;
}

.ant-slider:hover .ant-slider-track {
  background-color: #e8e8e8;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: .6rem;
  height: .6rem;
  border-width: .2em;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}

.col,
.col-md-2,
.col-md-4,
.col-md-6,
.col-md-8,
.col-md-12 {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.col-6 {
  position: relative;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
}

@media (min-width: 768px) {

  .col,
  .col-md-2,
  .col-md-4,
  .col-md-6,
  .col-md-8 {
    margin-bottom: 0;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666666%;
    flex: 0 0 66.666666%;
    max-width: 66.666666%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.game-stats>div {
  margin-top: 10px;
}

.play-by-play-quarter {
  position: relative;
}

.play-by-play-quarter-header {
  border-bottom-color: gray;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-top: 10px !important;
}

.play-by-play-point>span {
  display: inline-block
}

.play-by-play-point>span.play-by-play-score {
  width: 45px;
}

.play-by-play-point>span.play-by-play-is-o-line {
  width: 55px;
}

.play-by-play-point>span.play-by-play-clock {
  width: 50px;
}

.play-by-play-point>span.play-by-play-length {
  width: 60px;
}

.play-by-play-event>div {
  display: inline-block;
  vertical-align: middle;
}

.play-by-play-event>div.play-by-play-event-icon {
  width: 40px;
}

.play-by-play-event>div.play-by-play-event-icon>i {
  font-size: 2rem;
}

.play-by-play-event>div.play-by-play-event-icon>i:before {
  vertical-align: middle;
}

.play-by-play-event>div.play-by-play-event-distance {
  width: 40px;
}

.ui.styled.accordion .content {
  padding: .2em .4em;
}

.ui.styled.accordion .content .ui.segment {
  margin: 0.3rem 0;
  padding: .2em .4em;
}

.ui.styled.accordion .title {
  padding: .6em .8em;
  color: rgb(0, 0, 0, .6);
}

.ui.styled.accordion .play-by-play-point.title.we-scored {
  background-color: rgb(19, 184, 47, .6);
}

.ui.styled.accordion .play-by-play-point.title.we-scored.active,
.ui.styled.accordion .play-by-play-point.title.we-scored:hover {
  background-color: rgb(19, 184, 47, 1);
}

.ui.styled.accordion .play-by-play-point.title.they-scored {
  background-color: rgb(219, 18, 18, .6);
}

.ui.styled.accordion .play-by-play-point.title.they-scored.active,
.ui.styled.accordion .play-by-play-point.title.they-scored:hover {
  background-color: rgb(219, 18, 18, 1);
}

.ui.styled.accordion .play-by-play-point.title.no-one-scored {
  background-color: rgb(56, 143, 235, .6);
}

.ui.styled.accordion .play-by-play-point.title.no-one-scored.active,
.ui.styled.accordion .play-by-play-point.title.no-one-scored:hover {
  background-color: rgb(56, 143, 235, 1);
}

.ui.styled.accordion,
.ui.styled.accordion .accordion {
  box-shadow: none;
  border: 1px lightgray solid;
}

/* REMOVE BEFORE DEPLOY */

.quarter-breakdown table {
  margin: 10px 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
  width: unset;
}

.quarter-breakdown td,
.quarter-breakdown th {
  border: 1px solid #ccc;
  padding: 0.1em 0.6em;
  background-color: #fff;
  min-width: 20px;
}

.quarter-breakdown td:not(:first-child),
.quarter-breakdown th:not(:first-child) {
  vertical-align: middle;
  text-align: center;
}

.team-stats table {
  margin: 10px 0;
  padding: 0;
  border: 1px solid #ccc;
  border-spacing: 0;
  border-collapse: collapse;
  width: unset;
}

.team-stats td,
.team-stats th {
  vertical-align: middle;
  text-align: left;
  padding: 5px 7px;
  margin: 0;
}

.team-stats tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em;
  background-color: #fff;
}

.team-stats tr:nth-child(2n+2) {
  background-color: #f5f5f5;
}

.ui.table td {
  padding: 0;
}

.ui.table td>a {
  color: black;
  height: 52px;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.ui.table td>a:hover {
  color: black;
}